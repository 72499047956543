import React from 'react';

import fullCA01 from '../images/fullCA01.jpg';
import fullCA02 from '../images/fullCA02.jpg';

export default function CA() {
  return (
    <div className='wrapper'>
      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                Cricket Australia
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              The Streak is a bespoke project for Cricket Australia based on the Big Bash 2014/15 League season. Users have 5 lives over the course of the season and have to select which player, if any, will hit a 6 during each match. Cricket Australia have a very specific brand which had to be matched throughout the game site.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image items-are-full-width'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <img
                alt='Home page web preview of the Big Bash Streak game site within a desktop computer'
                src={fullCA01}
              />
            </li>
            <li className='item list-image__item'>
              <img
                alt='Team page screenshot of the Big Bash Streak game site'
                src={fullCA02}
              />
            </li>
          </ul>

        </div>
      </article>
    </div>
  );
}
