import React, { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import emailjs from '@emailjs/browser';

export default function Contact() {

  const form = useRef();
  const {
    register, handleSubmit, reset, formState: { errors }
  } = useForm({ defaultValues: { user_name: '', user_email: '', message: '' } });

  const [statusMessage, setStatusMessage] = useState('');

  const onSubmit = (data) => {
    const contactStatus = document.querySelector('.contact__status');

    emailjs.sendForm('service_6szbs1b', 'template_dfjxg1o', form.current, 'A9yqqNrNGnBWcsHLj')
      .then((result) => {
        console.log(result.text);
        reset();
        setStatusMessage('Message sent');
        contactStatus.className = 'contact__status is-success';
        setTimeout(()=> {
          setStatusMessage('');
          contactStatus.className = 'contact__status';
        }, 5000);
      }, (error) => {
        console.log(error.text);
        setStatusMessage('Failed to send message, please try again');
        contactStatus.className = 'contact__status is-error';
        setTimeout(()=> {
          setStatusMessage('');
          contactStatus.className = 'contact__status';
        }, 5000);
      });
  };

  return (
    <div className='contact'>

      <div className='contact__status'>
        {statusMessage
          ? statusMessage
          : errors.user_name
            ? 'Please complete the fields and resubmit'
            : errors.user_email
              ? 'Please complete the fields and resubmit'
              : errors.message
                ? 'Please complete the fields and resubmit'
                : ''
        }
      </div>

      <form id='contact-form' ref={form} onSubmit={handleSubmit(onSubmit)}>

        <input
          className='contact__input-name'
          type='text'
          name='user_name'
          placeholder='Name'
          {...register('user_name', {
            required: true
          })}
        />

        <input
          className='contact__input-email'
          type='email'
          name='user_email'
          placeholder='Email'
          {...register('user_email', {
            required: true
          })}
        />

        <textarea
          className='contact__input-message'
          rows='5'
          name='message'
          placeholder='Message'
          maxLength='1500'
          {...register('message', {
            required: true
          })}
        />

        <div className='contact__btn-container'>
          <input
            className='btn-text contact__btn'
            type='submit'
            value='Send'
          />
        </div>

      </form>
    </div>
  );
}
