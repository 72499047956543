import React from 'react';

import fullRSH01 from '../images/fullRSH01.jpg';
import fullRSH02 from '../images/fullRSH02.jpg';

export default function RSH() {
  return (
    <div className='wrapper'>
      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                Red Spotted Hanky
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              Based on the 2014 World Cup, the main objectives of the site were to attract new users whilst also re-engaging current customers by offering high value prizes, and loyalty points for each fantasy point scored. The site was required to stay within the Red Spotted Hanky brand boundaries but feature a Brazilian football theme.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image items-are-full-width'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <img
                alt='Home page web preview of the Red Spotted Hanky World Cup game site within a laptop'
                src={fullRSH01}
              />
            </li>
            <li className='item list-image__item'>
              <img
                alt='Team page web preview of the Red Spotted Hanky World Cup game site as shown in a desktop computer'
                src={fullRSH02}
              />
            </li>
          </ul>

        </div>
      </article>
    </div>
  );
}
