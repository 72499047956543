import React from 'react';
import { Link } from 'react-router-dom';

import thumbsJumps from '../images/thumbJumps.jpg';
import thumbsAxiata from '../images/thumbAxiata.jpg';
import thumbsRSH from '../images/thumbRSH.jpg';
import thumbsMBC from '../images/thumbMBC.jpg';
import thumbsMOTD from '../images/thumbMOTD.jpg';
import thumbsCA from '../images/thumbCA.jpg';

export default function Portfolio() {

  return (
    <div className='wrapper'>

      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                Welcome!
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              I'm Guy, a Front End Developer based in Brighton who loves everything web.<br/><br/>Feel free to check out the projects I've worked on and get in touch if you'd like to know more.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image items-are-third-width'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <Link
                className='list-image__item-link'
                to='/jumps'
              >
                <img
                  alt='William Hill Logo'
                  src={thumbsJumps}
                />
              </Link>
            </li>
            <li className='item list-image__item'>
              <Link
                className='list-image__item-link'
                to='/axiata'
              >
                <img
                  alt='Axiata Cup Logo'
                  src={thumbsAxiata}
                />
              </Link>
            </li>
            <li className='item list-image__item'>
              <Link
                className='list-image__item-link'
                to='/rsh'
              >
                <img
                  alt='Red Spotted Hanky Logo'
                  src={thumbsRSH}
                />
              </Link>
            </li>
            <li className='item list-image__item'>
              <Link
                className='list-image__item-link'
                to='/mbc'
              >
                <img
                  alt='MBC Pro Sports Logo'
                  src={thumbsMBC}
                />
              </Link>
            </li>
            <li className='item list-image__item'>
              <Link
                className='list-image__item-link'
                to='/motd'
              >
                <img
                  alt='MOTD Magazine Logo'
                  src={thumbsMOTD}
                />
              </Link>
            </li>
            <li className='item list-image__item'>
              <Link
                className='list-image__item-link'
                to='/ca'
              >
                <img
                  alt='Cricket Australia Logo'
                  src={thumbsCA}
                />
              </Link>
            </li>
          </ul>

        </div>
      </article>

    </div>
  );
}
