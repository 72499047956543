import React from 'react';

import Contact from './Contact';

export default function Footer() {
  return (
    <footer className='footer'>
      <div className='footer__inner'>

        <Contact />

        <div className='footer__copyright'>
          <div className='footer__copyright-inner'>
            Copyright © <script>document.write(new Date().getFullYear())</script>2022<noscript>2022</noscript> Guy Willis. All Rights Reserved.
          </div>
        </div>

        <div className='footer__disclaimer'>
          <div className='footer__disclaimer-inner'>
            Any of the trademarks, service marks, collective marks, design rights or similar rights that are mentioned, used or cited above are the property of their respective owners.
          </div>
        </div>

      </div>
    </footer>
  );
}
