import React from 'react';

import fullMBC01 from '../images/fullMBC01.jpg';
import fullMBC02 from '../images/fullMBC02.jpg';

export default function MBC() {
  return (
    <div className='wrapper'>
      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                MBC
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              Created for MBC Pro Sports and based on the Saudi Pro League, this project proved to be quite interesting. The immediate challenge was to reverse the entire template product (including the game structure) to read from RTL (right-to-left) in order to support the Arabic language. Any improvements to the efficiency of this reversal would then be filtered back into the template product to make this process easier in the future.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image items-are-full-width'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <img
                alt='Home page web preview of the Arabic version of MBC Pro Sports fantasy game site within a laptop'
                src={fullMBC01}
              />
            </li>
            <li className='item list-image__item'>
              <img
                alt='Team page screenshot of the Arabic version of MBC Pro Sports fantasy game site'
                src={fullMBC02}
              />
            </li>
          </ul>

        </div>
      </article>
    </div>
  );
}
