import React from 'react';
import { Link } from 'react-router-dom';

export default function Nav() {
  return (
    <nav className='nav'>
      <div className='nav__inner'>

        <div className='nav__title'>
          <div className='nav__title-inner'>

            <Link
              className='nav__link'
              to='/'
            >
              Guy Willis
            </Link>

          </div>
        </div>

      </div>
    </nav>
  );
}
