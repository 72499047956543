import React from 'react';

import fullMOTD01 from '../images/fullMOTD01.jpg';
import fullMOTD02 from '../images/fullMOTD02.jpg';

export default function MOTD() {
  return (
    <div className='wrapper'>
      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                MOTD Magazine
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              Designed for children, MOTD Magazine's Fantasy Premier League game boasts a vibrant colour scheme, a plethora of additional features to promote user engagement and numerous promotional banners to increase magazine sales.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image items-are-full-width'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <img
                alt='Home page web preview of the MOTD Magazine game site within a desktop computer'
                src={fullMOTD01}
              />
            </li>
            <li className='item list-image__item'>
              <img
                alt='Team page mobile preview of the MOTD Magazine game site shown in a smartphone'
                src={fullMOTD02}
              />
            </li>
          </ul>

        </div>
      </article>
    </div>
  );
}
