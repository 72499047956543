import './scss/App.scss';

import Nav from './components/Nav';
import Portfolio from './components/Portfolio';
import Footer from './components/Footer';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Jumps from './components/Jumps';
import Axiata from './components/Axiata';
import RSH from './components/RSH';
import MBC from './components/MBC';
import MOTD from './components/MOTD';
import CA from './components/CA';

function App() {

  var isTouchDevice = ('ontouchstart' in document.documentElement);
  console.log(isTouchDevice);

  return (
    <div className='app'>
      <Router>

        <Nav />

        <Routes>
          <Route
            path='/'
            element={<Portfolio />}
          />
          <Route
            path='/jumps'
            element={<Jumps />}
          />
          <Route
            path='/axiata'
            element={<Axiata />}
          />
          <Route
            path='/rsh'
            element={<RSH />}
          />
          <Route
            path='/mbc'
            element={<MBC />}
          />
          <Route
            path='/motd'
            element={<MOTD />}
          />
          <Route
            path='/ca'
            element={<CA />}
          />
        </Routes>

        <Footer />

      </Router>
    </div>
  );
}

export default App;
