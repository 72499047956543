import React from 'react';

import fullJumps01 from '../images/fullJumps01.jpg';
import fullJumps02 from '../images/fullJumps02.jpg';

export default function Jumps() {
  return (
    <div className='wrapper'>
      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                William Hill 'The Jumps'
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              'The Jumps' is a cross-platform online interface available to customers of William Hill who have selected 10 horses from 'The Jumps Easy Slip'. The design of the interface had to be simple and intuitive yet also contain as much detailed information as the user may want access to.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image items-are-full-width'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <img
                alt='Team page web preview of The Jumps within a desktop computer'
                src={fullJumps01}
              />
            </li>
            <li className='item list-image__item'>
              <img
                alt='Team page mobile preview of The Jumps as shown in a smartphone'
                src={fullJumps02}
              />
            </li>
          </ul>

        </div>
      </article>
    </div>
  );
}
