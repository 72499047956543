import React from 'react';

import fullAxiata01 from '../images/fullAxiata01.jpg';
import fullAxiata02 from '../images/fullAxiata02.jpg';

export default function Axiata() {
  return (
    <div className='wrapper'>
      <header className='section about header'>
        <div className='header__inner'>

          <div className='header__title'>
            <div className='header__title-inner'>
              <h1 className='h1 header__heading'>
                Axiata Cup
              </h1>
            </div>
          </div>

          <div className='header__body'>
            <div className='header__body-inner'>
              The Axiata Cup Fantasy Badminton game was based on the real results of matches in the 2014 Axiata Cup and produced in two langauges, English and Bahasa Indonesia. The final product was a highly customised version of the standard template to match a very strict set of branding guidelines whilst also featuring a login and 'SmashPoints' integration, where users could earn points that they could redeem against prizes.
            </div>
          </div>

        </div>
      </header>

      <article className='list-image'>
        <div className='list-image__inner'>

          <ul className='list-image__list'>
            <li className='item list-image__item'>
              <img
                alt='Team page screenshot of the English version of the Axiata Cup game site'
                src={fullAxiata01}
              />
            </li>
            <li className='item list-image__item'>
              <img
                alt='Team page screenshot of the Bahasa Indonesia version of the Axiata Cup game site'
                src={fullAxiata02}
              />
            </li>
          </ul>

        </div>
      </article>
    </div>
  );
}
